import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SectionName, useAuth } from "../App";
import { FaUserCircle } from "react-icons/fa";

interface NavItemProps {
  section: SectionName | "privacy" | "carrier-setup" | "account";
  onClick: (section: SectionName | "privacy" | "carrier-setup" | "account") => void;
  children: React.ReactNode;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ section, onClick, children, isActive }) => (
  <button onClick={() => onClick(section)} className={`px-3 py-2 rounded-md text-sm font-medium cursor-pointer transition-colors duration-200 ${isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white"}`}>
    {children}
  </button>
);

interface NavigationProps {
  scrollToSection: (section: SectionName) => void;
}

const Navigation: React.FC<NavigationProps> = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<SectionName | "privacy" | "carrier-setup" | "account">("home");
  const { user: authUser, userRoles } = useAuth();
  const isAdmin = userRoles.includes("admin");
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const AdminDashboardItem: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
    if (!isAdmin) return null;
    return (
      <NavItem section="dashboard" onClick={handleNavClick} isActive={activeSection === "dashboard"}>
        DASHBOARD
      </NavItem>
    );
  };

  const handleNavClick = (section: SectionName | "privacy" | "carrier-setup" | "account" | "dashboard") => {
    if (section === "privacy") {
      navigate("/privacy-notice");
      setActiveSection("privacy");
    } else if (section === "carrier-setup") {
      navigate("/carrier-setup");
      setActiveSection("carrier-setup");
    } else if (section === "account") {
      navigate("/account");
      setActiveSection("account");
    } else if (section === "dashboard" && isAdmin) {
      navigate("/dashboard");
      setActiveSection("dashboard");
    } else if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scrollToSection(section as SectionName);
        setActiveSection(section as SectionName);
      }, 100);
    } else {
      scrollToSection(section as SectionName);
      setActiveSection(section as SectionName);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (location.pathname === "/privacy-notice") {
      setActiveSection("privacy");
    } else if (location.pathname === "/carrier-setup") {
      setActiveSection("carrier-setup");
    } else if (location.pathname === "/account" || location.pathname === "/login" || location.pathname === "/register") {
      setActiveSection("account");
    } else {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const sections = document.querySelectorAll("section");

        sections.forEach((section) => {
          const sectionTop = section.offsetTop - 64;
          const sectionBottom = sectionTop + section.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            const currentSection = section.id as SectionName;
            if (currentSection !== activeSection) {
              setActiveSection(currentSection);
            }
          }
        });
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [activeSection, location.pathname]);

  const navItems: SectionName[] = ["home", "about", "services", "contact", "faq"];

  return (
    <nav className="bg-gray-800 fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <NavLink to="/" className="flex-shrink-0">
              <div className="flex items-center">
                <span className="text-blue-500 text-2xl font-bold">R</span>
                <span className="text-white text-xl font-semibold tracking-wider">emberg</span>
                <span className="text-blue-300 text-xl font-light ml-2">Logistics</span>
                <span className="text-gray-400 text-sm ml-1">INC</span>
              </div>
            </NavLink>
            <div className="hidden lg:block">
              <div className="ml-10 flex items-center space-x-4">
                {navItems.map((item) => (
                  <NavItem key={item} section={item} onClick={handleNavClick} isActive={activeSection === item}>
                    {item.toUpperCase()}
                  </NavItem>
                ))}
                <NavItem section="privacy" onClick={handleNavClick} isActive={activeSection === "privacy"}>
                  PRIVACY NOTICE
                </NavItem>
                <NavItem section="carrier-setup" onClick={handleNavClick} isActive={activeSection === "carrier-setup"}>
                  CARRIER SETUP
                </NavItem>

                <AdminDashboardItem isAdmin={isAdmin} />

                <NavItem section="account" onClick={handleNavClick} isActive={activeSection === "account"}>
                  <FaUserCircle className="h-6 w-6" />
                  <span className="sr-only">
                    Navigate to Account
                  </span>
                </NavItem>
              </div>
            </div>
          </div>
          <div className="flex items-center lg:hidden">
            {/* Profile icon for mobile */}
            <button 
              onClick={() => handleNavClick("account")} 
              aria-label={`Navigate to Account`}
              className="p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <FaUserCircle className="h-6 w-6" />
            </button> 
            {/* Mobile menu button */}
            <button
              onClick={toggleMenu}
              type="button"
              className="ml-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      <div className={`lg:hidden ${isOpen ? "block" : "hidden"}`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col">
          {navItems.map((item) => (
            <NavItem key={item} section={item} onClick={handleNavClick} isActive={activeSection === item}>
              {item.toUpperCase()}
            </NavItem>
          ))}
          <NavItem section="privacy" onClick={handleNavClick} isActive={activeSection === "privacy"}>
            PRIVACY NOTICE
          </NavItem>
          <NavItem section="carrier-setup" onClick={handleNavClick} isActive={activeSection === "carrier-setup"}>
            CARRIER SETUP
          </NavItem>
          
          <AdminDashboardItem isAdmin={isAdmin} />

        </div>
      </div>
    </nav>
  );
};

export default Navigation;
